<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<div class="title-container">
				<div class="title-content">
					<h1>Health Monitoring Questionnaire</h1>
					<p>Kindly fill out the following information.</p>
				</div>
			</div>
			<div class="content-container">
				<div class="feeling-container" v-if="hasSymptoms === ''">
					<h1 class="feeling-title">How are you feeling {{isPreviousDate ? 'on that day' : 'today'}}?</h1>
					<ion-row class="feeling-content">
						<ion-col>
							<ion-button @click="hasSymptoms = false;" shape="round" fill="outline" color="success" class="feeling-buttons" expand="full">
								<div class="feeling-button-content">
									<ion-ripple-effect></ion-ripple-effect>
									<img src="@/assets/misc/feeling-well.svg" class="feeling-button-icon" contain />
								</div>
							</ion-button>
						</ion-col>
						<ion-col>
							<ion-button @click="hasSymptoms = true;" shape="round" fill="outline" color="danger" class="feeling-buttons" expand="full">
								<div class="feeling-button-content">
									<ion-ripple-effect></ion-ripple-effect>
									<img src="@/assets/misc/not-feeling-well.svg" class="feeling-button-icon" contain />
								</div>
							</ion-button>
						</ion-col>
					</ion-row>
				</div>
				<ion-card class="content-container-card" v-if="hasSymptoms === true" color="danger">
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-icon class="message-icon" name="sad-sharp" size="large" color="light"></ion-icon>
										<h1 class="message-text">You are not feeling well {{isPreviousDate ? 'on that day' : 'today'}}.</h1>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<div end="end">
									<ion-button @click="resetItemsDialog" class="card-action-button-change" color="light">Change</ion-button>
								</div>
							</ion-col>
						</ion-row>
						<ion-row>
							<div class="message-subtitle-container">
								<p>Please seek immediate medical attention if symptoms persist and inform HR.</p>
							</div>
						</ion-row>
					</ion-card-content>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasSymptoms === false" color="success">
					<ion-card-content>
						<ion-row class="content-item-container">
							<ion-col size="9">
								<div start="start">
									<div class="message-title-container">
										<ion-icon class="message-icon" name="happy-sharp" size="large" color="light"></ion-icon>
										<h1 class="message-text">Yey! You are feeling well {{isPreviousDate ? 'on that day' : 'today'}}</h1>
									</div>
								</div>
							</ion-col>
							<ion-col class="card-action-button" size="3">
								<div end="end">
									<ion-button @click="resetItemsDialog" class="card-action-button-change" color="light">Change</ion-button>
								</div>
							</ion-col>
						</ion-row>
					</ion-card-content>
					<ion-button slot="right">Change</ion-button>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasSymptoms === true || hasSymptoms === false">
					<ion-card-header class="content-card-title">
						<ion-card-subtitle>Date:</ion-card-subtitle>
					</ion-card-header>
					<ion-card-content>
						<ion-card-title>
							<ion-item class="input-item-custom">
								<ion-label position="floating">MM/DD/YYYY</ion-label>
								<ion-datetime
									readonly
									:value="filledDate"
									@ionChange="filledDate = $event.target.value;"
									display-format="MM/DD/YYYY"
									:min="minDate"
									:max="maxDate">
								</ion-datetime>
							</ion-item>
						</ion-card-title>
					</ion-card-content>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasSymptoms === true">
					<ion-card-header>
						<ion-card-subtitle class="content-title">What are the symptoms you're experiencing right now?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>Anu-ano po ang mga sintomas na iyong nararanasan?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-list>
								<ion-item class="input-item-custom">
									<ion-label>Sore throat (Pananakit ng lalamunan / masakit lumunok)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasSoreThroat"
										@ionChange="hasSoreThroat = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Body pains (Pananakit ng katawan)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasBodyPains"
										@ionChange="hasBodyPains = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Headache (Pananakit ng ulo)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasHeadache"
										@ionChange="hasHeadache = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Fever (Lagnat)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasFever"
										@ionChange="hasFever = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Loss of taste or smell (Nawalan ng panglasa o pangamoy)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasLossTasteSmell"
										@ionChange="hasLossTasteSmell = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Diarrhea (pagtatae)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasDiarrhea"
										@ionChange="hasDiarrhea = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Chest Pains (pananakit ng dibdib)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasChestPains"
										@ionChange="hasChestPains = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
								<ion-item class="input-item-custom">
									<ion-label>Conjunctivitis (pamumula ng mata)</ion-label>
									<ion-checkbox
										end="end"
										:value="hasConjunctivitis"
										@ionChange="hasConjunctivitis = $event.detail.checked;"
									>
									</ion-checkbox>
								</ion-item>
							</ion-list>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasSymptoms === false || (hasSymptoms === true && (hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis))">
					<ion-card-header>
						<ion-card-subtitle class="content-title">Is there a confirmed case from your barangay/street?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>May kumpirmadong kaso na ba ng COVID-19 sa inyong brgy/area of residence?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-list>
								<ion-radio-group 
									class="radio-container"
									:value="hasConfirmedCase"
									@ionChange="hasConfirmedCase = $event.target.value;"
								>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="true"></ion-radio>
										<ion-label>Yes</ion-label>
									</ion-item>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="false"></ion-radio>
										<ion-label>No</ion-label>
									</ion-item>
								</ion-radio-group>
							</ion-list>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasConfirmedCase !== '' && (hasSymptoms === false || hasSymptoms === true || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
					<ion-card-header>
						<ion-card-subtitle class="content-title">Have you worked together or stayed in the same close environment of a confirmed COVID-19 case?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>May nakasama ka ba o nakatrabahong tao na kumpirmadong may COVID-19 / may impeksyon ng coronavirus?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-list>
								<ion-radio-group 
									class="radio-container"
									:value="hasWorkedWithConfirmedCase"
									@ionChange="hasWorkedWithConfirmedCase = $event.target.value;"
								>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="true"></ion-radio>
										<ion-label>Yes</ion-label>
									</ion-item>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="false"></ion-radio>
										<ion-label>No</ion-label>
									</ion-item>
								</ion-radio-group>
							</ion-list>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasWorkedWithConfirmedCase !== '' && (hasSymptoms === false || hasSymptoms === true || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)"> 
					<ion-card-header>
						<ion-card-subtitle class="content-title">Have you had any contact with anyone with fever, cough, colds and sore throat in the past 2 weeks?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>Mayroon ka bang nakasama na may lagnat, ubo, sipon o sakit ng lalamunan sa nakalipas ng dalawang (2) linggo?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-list>
								<ion-radio-group 
								class="radio-container"
								:value="hasContactWithSymptoms"
								@ionChange="hasContactWithSymptoms = $event.target.value;"
								>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="true"></ion-radio>
										<ion-label>Yes</ion-label>
									</ion-item>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="false"></ion-radio>
										<ion-label>No</ion-label>
									</ion-item>
								</ion-radio-group>
							</ion-list>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasContactWithSymptoms !== '' && (hasSymptoms === false || hasSymptoms === true || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
					<ion-card-header>
						<ion-card-subtitle class="content-title">Have you travelled outside of the Philippines in the last 14 days?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>Ikaw ba ay nagbyahe sa labas ng Pilipinas sa nakalipas na 14 na araw?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-list>
								<ion-radio-group 
									class="radio-container"
									:value="hasTravelledOutsidePH"
									@ionChange="hasTravelledOutsidePH = $event.target.value;"
								>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="true"></ion-radio>
										<ion-label>Yes</ion-label>
									</ion-item>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="false"></ion-radio>
										<ion-label>No</ion-label>
									</ion-item>
								</ion-radio-group>
							</ion-list>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasTravelledOutsidePH !== '' && (hasSymptoms === false || hasSymptoms === true || hasContactWithSymptoms !== '' || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
					<ion-card-header>
						<ion-card-subtitle class="content-title">Have you travelled to any area aside from your home?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>Ikaw ba ay nagpunta sa ibang lugar bukod sa iyong bahay?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-list>
								<ion-radio-group 
									class="radio-container"
									:value="hasTravelledOutsideHome"
									@ionChange="hasTravelledOutsideHome = $event.target.value;"
								>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="true"></ion-radio>
										<ion-label>Yes</ion-label>
									</ion-item>
									<ion-item class="input-item-custom" lines="none">
										<ion-radio class="radio-button" :value="false"></ion-radio>
										<ion-label>No</ion-label>
									</ion-item>
								</ion-radio-group>
							</ion-list>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
				<ion-card class="content-container-card" v-if="hasTravelledOutsideHome === true  && (hasSymptoms === false || hasSymptoms === true || hasContactWithSymptoms !== '' || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)">
					<ion-card-header>
						<ion-card-subtitle class="content-title">What places did you visit?</ion-card-subtitle>
						<ion-card-subtitle class="content-subtitle"><i>Saang lugar ka nagpunta?</i></ion-card-subtitle>
						<ion-card-title>
							<ion-input 
							class="mainform-input"
							:value="placeVisited"
							@ionInput="placeVisited = $event.target.value;"
							placeholder="Insert Place here"></ion-input>
						</ion-card-title>
					</ion-card-header>
				</ion-card>
			</div>
			<div class="button-container">
				<ion-button class="button-cancel" color="danger" @click="cancelEntry">Cancel</ion-button>
				<ion-button class="button-confirm" v-if="hasTravelledOutsideHome !== ''  && (hasContactWithSymptoms !== '' || hasWorkedWithConfirmedCase !== '' || hasConfirmedCase !== '' || hasSymptoms === false || hasSoreThroat || hasBodyPains || hasHeadache || hasFever || hasLossTasteSmell || hasDiarrhea || hasChestPains || hasConjunctivitis)" @click="submitEntry">Save Form</ion-button>
			</div>
		</ion-content>
	</ion-page>
</template>
<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

import { 
	alertController, 
	toastController,
	loadingController,
	IonRippleEffect,
	IonButton,
	IonCol,
	IonRow,
	IonIcon,
	IonCardContent,
	IonCard,
	IonCardSubtitle,
	IonCardHeader,
	IonLabel,
	IonDatetime,
	IonItem,
	IonCardTitle,
	IonCheckbox,
	IonList,
	IonRadio,
	IonRadioGroup,
	IonInput,
	IonPage,
	IonContent
} from '@ionic/vue'

export default {
	name: 'MainFormContainer',
		props: {
			userData: {
				type: Object
			},
		},
	data() {
		return {
			maxDate: '',
			minDate: '',
			filledDate: format(new Date(), 'yyyy-MM-dd'),
			hasSymptoms: '',
			hasSoreThroat: '',
			hasBodyPains: '',
			hasHeadache: '',
			hasFever: '',
			hasLossTasteSmell: '',
			hasDiarrhea: '',
			hasChestPains: '',
			hasConjunctivitis: '',
			hasConfirmedCase: '',
			hasWorkedWithConfirmedCase: '',
			hasContactWithSymptoms: '',
			hasTravelledOutsidePH: '',
			hasTravelledOutsideHome: '',
			placeVisited: '',
			isPreviousDate: false,
			consent: false,
			currentUser: {}
		}
	},
	components: {
		IonRippleEffect,
		IonButton,
		IonRow,
		IonIcon,
		IonCardContent,
		IonCard,
		IonCardSubtitle,
		IonCardHeader,
		IonLabel,
		IonDatetime,
		IonItem,
		IonCardTitle,
		IonCheckbox,
		IonList,
		IonRadio,
		IonRadioGroup,
		IonInput,
		IonPage,
		IonCol,
		IonContent
	},
	computed: {
		...mapGetters([
			"login/currUser",
			"login/isAuthenticated"
		]),
	},
	watch: {
    '$route': function (to, from){
      if (to.name === "declarationForm" && from.name === 'dashboard') {
				window.scrollTo(0,0);
				// this.$router.go(to.name);
				if (localStorage.getItem('previous_date')) {
					this.isPreviousDate = true;
					let prevDate = localStorage.getItem('previous_date');
					this.filledDate = format(new Date(prevDate), 'yyyy-MM-dd');
				}
      }
    }
  },
	mounted() {
		window.scrollTo(0,0);
		this.minDate = format(new Date('1990-01-01'), 'yyyy-MM-dd')
		this.maxDate = format(new Date(), 'yyyy-MM-dd')

		if (localStorage.getItem('previous_date')) {
			this.isPreviousDate = true;
			let prevDate = localStorage.getItem('previous_date');
			this.filledDate = format(new Date(prevDate), 'yyyy-MM-dd');
		}

		if (localStorage.getItem('is_consent_agreed') === null
		|| localStorage.getItem('is_consent_agreed') === undefined
		|| localStorage.getItem('is_consent_agreed') === "") {
			this.openAgreement();
		} else {
			this.consent = localStorage.getItem('is_consent_agreed');
		}
	},
	methods: {
		async openAgreement() {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Accept Terms and Conditions',
				backdropDismiss: false,
				message: 'I hereby authorize PETNET, Inc. to collect and process the data indicated herein for the purpose of implementing control of the COVID-19 infection.<br><br>I understand that my personal information is protected by RA 10173, Data Privacy Act of 2012, and that I am required by RA 11469, Bayanihan to Heal as One Act, to provide truthful information.',
				inputs: [
				{
					type: 'checkbox',
					label: 'I agree',
					value: 'isConfirmChecked',
					checked: false,
				}],
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							this.$router.push('/dashboard');  
						},
					},
					{
						text: 'Continue',
						handler: (data) => {
							if (data.length > 0) {
								if (data[0] === 'isConfirmChecked') {
									localStorage.setItem('is_consent_agreed', true);
								} else {
									this.openToast('You must agree to the consent.', 'danger')
									this.$router.push('/dashboard');
								}   
							} else {
								this.openToast('You must agree to the consent.', 'danger')
								this.$router.push('/dashboard');
							}
						},
					},
				],
			});
			return alert.present();
		},
		async resetItemsDialog() {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Change Response',
				backdropDismiss: false,
				message: 'Do you want to change your response? Any progress made will be lost.',
				buttons: [
					{
						text: 'No',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						},
					},
					{
						text: 'Yes',
						handler: () => {
							this.clearForm();
						},
					},
				],
			});

			return alert.present();
		},
		async cancelEntry() {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Cancel Entry',
				backdropDismiss: false,
				message: 'Are you sure you want to cancel the entry? Any unsaved changes will be lost.',
				buttons: [
					{
						text: 'No',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						},
					},
					{
						text: 'Yes',
						handler: () => {
							this.$router.push('/dashboard');
							localStorage.removeItem('previous_date');
							localStorage.removeItem('is_consent_agreed');
						},
					},
				],
			});

			return alert.present();
		},
		async submitEntry() {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Submit Form',
				backdropDismiss: false,
				message: 'I hereby state that the above declaration are true and correct to the best of my knowledge. Any misrepresentation thereof, resulting to compromised health and safety of my coworkers shall be subject to disciplinary action.',
				inputs: [
				{
					type: 'checkbox',
					label: 'I agree',
					value: 'isConfirmChecked',
					checked: false,
				}],
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						},
					},
					{
						text: 'Continue',
						handler: (data) => {
							let isComplete = false;
							let isSymptomsFilled = false;

							let payload = {
								date: format(new Date(this.filledDate), 'yyyy-MM-dd'),
								has_symptoms: this.hasSymptoms,
								has_sore_throat: this.hasSoreThroat !== '' ? this.hasSoreThroat : false,
								has_body_pains: this.hasBodyPains !== '' ? this.hasBodyPains : false,
								has_headache: this.hasHeadache !== '' ? this.hasHeadache : false,
								has_fever: this.hasFever !== '' ? this.hasFever : false,
								has_loss_taste_smell: this.hasLossTasteSmell !== '' ? this.hasLossTasteSmell : false,
								has_diarrhea: this.hasDiarrhea !== '' ? this.hasDiarrhea : false,
								has_chest_pains: this.hasChestPains !== '' ? this.hasChestPains : false,
								has_conjunctivitis: this.hasConjunctivitis !== '' ? this.hasConjunctivitis : false,
								has_confirmed_case: this.hasConfirmedCase,
								has_worked_with_confirmed_case: this.hasWorkedWithConfirmedCase,
								has_contact_with_symptoms: this.hasContactWithSymptoms,
								has_travelled_outside_ph: this.hasTravelledOutsidePH,
								has_travelled_outside_home: this.hasTravelledOutsideHome,
								places_visited: this.placeVisited
							}

							if (payload.has_symptoms === false) {
								payload.has_sore_throat = false;
								payload.has_body_pains = false;
								payload.has_headache = false;
								payload.has_fever = false;
								payload.has_loss_taste_smell = false;
								payload.has_diarrhea = false;
								payload.has_chest_pains = false;
								payload.has_conjunctivitis = false;
							}

							if (payload.has_travelled_outside_home === false) {
								payload.places_visited = 'N/A';
							}

							Object.keys(payload).forEach(key => {
								if (payload[key] === '') {
									isComplete = false;
								} else {
									isComplete = true;
								}
							})



							if (payload.has_symptoms === true) {
								if (payload.has_sore_throat === false && 
									payload.has_body_pains === false &&
									payload.has_headache === false &&
									payload.has_fever === false &&
									payload.has_loss_taste_smell === false &&
									payload.has_diarrhea === false &&
									payload.has_chest_pains === false &&
									payload.has_conjunctivitis === false) {
										isComplete = false;
										isSymptomsFilled = false;
								} else {
									isComplete = true;
									isSymptomsFilled = true;
								}
							} else {
								isSymptomsFilled = true;
							}
							
							if (isComplete === true && isSymptomsFilled === true) {
								if (data.length > 0) {
									if (data[0] === 'isConfirmChecked') {
										payload.declaration = true;
										this.submitMonitoringForm(payload);
									} else {
										payload.declaration = false;
										this.openToast('You must agree to the condition.', 'danger')
									}   
								} else {
									this.openToast('You must agree to the condition.', 'danger')
								}
							} else if (isSymptomsFilled === false) {
								this.openToast('Please indicate your symptoms.', 'danger')
							}	else {
								this.openToast('There are missing fields. Please Complete them.', 'danger')
							}
						},
					},
				],
			});

			return alert.present();
		},
		async openToast(msg, type) {
			const toast = await toastController
				.create({
					message: msg,
					animated: true,
					duration: 2000,
					color: type,
					cssClass: 'toast-custom',
					end: true
				})
			return toast.present();
		},
		async dismissLoading() {
			const loading = await loadingController;
			loading.dismiss()
		},
		async presentLoading() {
			const loading = await loadingController
				.create({
					cssClass: 'loading-container',
					message: 'Please wait...',
					duration: this.timeout,
				});
			await loading.present();
		},
		async submitMonitoringForm(payload) {
			this.presentLoading();

			let consent_response = localStorage.getItem('is_consent_agreed');
			let consent_res_conv = (consent_response === 'true');

			let submitForm = payload;
			let current_user = {};
			let bytes = "";
			let decryptedData = "";

			let cipherData = VueCookies.get('misc');

			if (this.userData) {
				current_user = this.userData;
			} else {
				bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
				decryptedData = bytes.toString(CryptoJS.enc.Utf8);

				if (decryptedData) {
					let decode = JSON.parse(decryptedData);
					let user = decode.user;
					current_user = user;
				} else {
					localStorage.setItem('is_session_expired', true);
					localStorage.removeItem("is_consent_agreed");
					VueCookies.remove("misc");
					VueCookies.remove("misc-hs");
					window.location.href = '/login';
				}
			}

			submitForm.branch = current_user.branch;
			submitForm.first_name = current_user.first_name;
			submitForm.last_name = current_user.last_name;
			submitForm.user_id = current_user.id;
			submitForm.email = current_user.email;
			submitForm.work_category = current_user.work_category;
			submitForm.department = current_user.assigned_department;
			submitForm.enable_teleconsultation = current_user.enable_teleconsultation == '' ? false : current_user.enable_teleconsultation;
			submitForm.agreement = consent_res_conv;

			await store.dispatch('questionnaire/submitForm', payload).then(res => {
				this.dismissLoading();
				const that = this;

				localStorage.removeItem('previous_date');
				localStorage.removeItem("is_consent_agreed");

				setTimeout(function() {
					
					that.clearForm();
					that.openToast(`<b>${res.data.message}</b>`, 'success');
				}, 1000);
				
				this.$router.push('/dashboard')
			}).catch((err) => {
				this.dismissLoading();

				if (err.status == 401) {
					localStorage.setItem('is_session_expired', true);
					localStorage.removeItem("is_consent_agreed");
					VueCookies.remove("misc");
					VueCookies.remove("misc-hs");
					window.location.href = '/login';
				}

				let errorFields = '';
				let errorMessage = err.data.errors;

				for (let key in errorMessage) {
						errorFields += errorMessage[key][0] + '<br>';
				}

				const that = this;

				setTimeout(function() {
					that.openToast('<b>Sorry, Invalid fields:</b><br><br>' + errorFields, 'danger')
				}, 1000);
			});
		},
		clearForm() {
			this.hasSymptoms = "";
			this.hasSoreThroat = "";
			this.hasBodyPains = "";
			this.hasHeadache = "";
			this.hasFever = "";
			this.hasLossTasteSmell = "";
			this.hasDiarrhea = "";
			this.hasChestPains = "";
			this.hasConjunctivitis = "";
			this.hasConfirmedCase = "";
			this.hasWorkedWithConfirmedCase = "";
			this.hasContactWithSymptoms = "";
			this.hasTravelledOutsidePH = "";
			this.hasTravelledOutsideHome = "";
			this.placeVisited = "";
			this.isPreviousDate = false;
			//Resets the date to current date
			this.filledDate = format(new Date(), 'yyyy-MM-dd');
			localStorage.removeItem('previous_date');
			localStorage.removeItem("is_consent_agreed");
		},
	}
};
</script>
<style scoped>
	.title-container {
		padding: 25px 11px;
		color: #FFF;
		background: rgb(2,0,36);
		background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(26,39,145,1) 35%, rgba(0,224,255,1) 100%);
	}

	.title-container h1 {
		font-weight: bolder;
		font-family: "Roboto Bold";
	}

	.title-content-notice {
		font-family: "Roboto Medium";
	}

	.content-title {
		font-family: "Roboto Medium";
		font-size: 18px;
		color: #000;
	}

	.content-subtitle i {
		font-size: 14px;
	}

	.radio-button {
		margin-right: 5px;
	}

	.radio-container {
		display: flex;
		flex-direction: row;
	}

	.button-container {
		padding: 0 11px;
		padding-bottom: 11px;
		text-align: right;
	}

	.feeling-container {
		padding: 0 11px;
	}

	.feeling-buttons {
		height: 250px;
	}

	.feeling-title {
		font-family: "Roboto Bold";
	}

	.feeling-content {
		display: block;
	}

	.feeling-button-icon {
		width: auto;
		margin: 0 auto;
		height: 150px;
	}

	.message-title-container {
		display: flex;
		flex-direction: row;
	}

	.message-icon {
		margin-right: 5px;
	}

	.message-text {
		margin: 2px;
		font-size: 18px;
	}

	.card-action-button { 
		text-align: left;
	}

	.card-action-button-change {
		margin: 0;
		width: 100%;
	}

	.button-confirm {
		margin-left: 10px;
	}

	.input-item-custom::part(native) {
		padding-left: 0;
	}

	.mainform-input {
		border-bottom: 1px solid black;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.content-item-container {
		display: block
	}

	@media (min-width: 768px) {
		.content-container-card,
		.button-container,
		.title-content,
		.feeling-container {
			width: 700px;
			margin: 25px auto;
		}

		.button-container {
			padding-right: 0;
		}

		.feeling-content {
			display: flex;
		}

		.message-text {
			font-size: 24px;
		}
		
		.title-content-name {
			font-size: 26px;
		}

		.content-card-data {
			font-size: 20px;
		}

		.content-item-container {
			display: flex
		}

		.card-action-button { 
			text-align: right;
		}

		.content-title {
			font-size: 20px;
		}

		.card-action-button-change {
			width: initial;
		}
	}
</style>